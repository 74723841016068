import React from "react"

import ReCAPTCHA from "react-google-recaptcha"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"
import axios from "axios"
import $ from "jquery"
import { navigate } from "@reach/router"
class ContactPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: null,
      email: null,
      phone: null,
      subscribed: true,
      service_inquiry: null,
      found_on: null,
      message: null,
      captcha: false,
    }

    this.captchaOnSuccess = this.captchaOnSuccess.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.onClickHandler = this.onClickHandler.bind(this)
    this.handleCheckmark = this.handleCheckmark.bind(this)
  }

  componentDidMount() {
    $(document).on("click", ".service_inquiry_outer .active", function(e) {
      e.stopPropagation()
      e.preventDefault()
      e.stopImmediatePropagation()
      $(".service_inquiry_outer .si_options").slideDown()
    })
    let that = this
    $(document).on("click", ".si_options>li", function(e) {
      e.stopPropagation()
      e.preventDefault()
      e.stopImmediatePropagation()
      if ($(this).find(".si_options_sub").length && !$(this).hasClass("open")) {
        $(".si_options_sub").slideUp()
        $(".si_options>li.open").removeClass("open")
        $(this).addClass("open")
        $(this)
          .find(".si_options_sub")
          .slideDown()
      } else {
        $(".js-ddl-i").val(
          $(this)
            .find("span")
            .text()
        )
        $(".service_inquiry_outer .active").text(
          $(this)
            .find("span")
            .text()
        )
        that.setState({
          service_inquiry: $(this)
            .find("span")
            .text(),
        })
        $(".service_inquiry_outer .si_options").slideUp()
        $(".si_options_sub").slideUp()
      }
    })
    $(document).on("click", ".si_options_sub>li", function(e) {
      e.stopPropagation()
      $(this)
        .closest("li.open")
        .removeClass("open")
      $(".js-ddl-i").val($(this).text())
      that.setState({
        service_inquiry: $(this).text(),
      })
      $(".service_inquiry_outer .active").text($(this).text())
      $(".service_inquiry_outer .si_options").slideUp()
      $(".si_options_sub").slideUp()
    })
    $(document).on("mouseleave", ".service_inquiry_outer", function() {
      window.___t = setTimeout(function() {
        $("li.open").removeClass("open")
        $(".si_options").slideUp()
      }, 10000)
    })
    $(document).on("mouseenter", ".service_inquiry_outer", function() {
      clearTimeout(window.___t)
    })
    $(document).on("click", ".js--open-popup", function() {
      $(".js--list").toggleClass("d-block")
    })
    $(document).on("click", ".selectboxit-option", function() {
      document.getElementById("ddl_howSelectBoxItText").textContent = $(
        this
      ).data("val")
      $(".js-ddl-e").val($(this).data("val"))
      that.setState({
        found_on: $(this).data("val"),
      })
    })
  }

  captchaOnSuccess() {
    this.setState({ captcha: !this.state.captcha })
  }
  handleCheckmark() {
    this.setState({
      subscribed: !this.state.subscribed,
    })
  }
  handleInputChange(event) {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }
  onClickHandler = event => {
    if (this.state.captcha) {
      event.preventDefault()
      const data = new FormData()
      data.append("Name", this.state.name)
      data.append("Email", this.state.email)
      data.append("Phone", this.state.phone)
      data.append("Service Inquiry", this.state.service_inquiry)
      data.append("Subscribed", this.state.subscribed)
      data.append("Found On", this.state.found_on)
      data.append("Message", this.state.message)
      data.append("_next", "/mobile/thank-you.html")
      data.append("_cc", "drkaloti@thesmilestylist.ca,press@thesmilestylist.ca")
      axios
        .post("https://formspree.io/xvegqqym", data, {
          // receive two    parameter endpoint url ,form data
        })
        .then(() => {
          navigate("/thank-you")
        })
    } else {
      event.preventDefault()
      document.querySelector(".captcha").scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      })
      document.querySelector(".captcha").classList += " captcha_highlight"
    }
  }
  render() {
    return (
      <Layout>
        <SEO
          title="Contact Us | Free Smile Consultation | The Smile Stylist"
          description="Have questions regarding The Smile Stylist Cosmetic Dental Clinic? Contact us here regarding any cosmetic dentistry services, to book a free consult or an appointment. "
          keywords="Porcelain Veneers, Smile Makeover, Porcelain veneers cost, Smile Consultations"
        />
        <main className="site-content" role="main">
          <section id="main-top-img" className="contact hidden-xs">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 sec-title wow animated fadeInDown">
                  <h1>Contact Us</h1>
                  <h2>We’d love to hear from you!</h2>
                </div>
              </div>
            </div>
          </section>

          <section id="main-white" className="contact-sec">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 wow animated fadeInRight book_abs">
                  <div className="book_text">
                    <h2 className="contact__head">
                      BOOK AN APPOINTMENT, ASK US A QUESTION OR JUST SAY HELLO
                    </h2>
                    <p className="contact__sub">
                      <span className="letters-spaced">If</span> you’d like
                      <span className="letters-spaced">to</span> schedule{" "}
                      <span className="letters-spaced">an</span> appointment,
                      <span className="letters-spaced">or</span> have questions
                      about our practice please fill out the contact form below
                      or call us directly at&nbsp;(905)&nbsp;849-0558{" "}
                    </p>
                  </div>
                  <form method="post" action="https://formspree.io/xyyzvvwg">
                    <input type="hidden" name="_next" value="/thank-you" />
                    <div className="col-sm-12">
                      <div className="form-group">
                        <input
                          onChange={this.handleInputChange}
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Name"
                          className="form-control contact__field"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <input
                          onChange={this.handleInputChange}
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Email"
                          className="form-control contact__field"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <input
                          onChange={this.handleInputChange}
                          type="tel"
                          name="phone"
                          id="phone"
                          placeholder="Phone"
                          className="form-control contact__field"
                        />
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="form-group">
                        <div className="service_inquiry_outer">
                          <span className="active">Service Inquiry:</span>
                          <ul className="si_options">
                            <li>
                              <span className="arr">Book An Appointment</span>
                              <ul className="si_options_sub">
                                <li>Cleaning</li>
                                <li>Teeth Whitening</li>
                                <li>Botox</li>
                                <li>Other </li>
                              </ul>
                            </li>
                            <li>
                              <span className="arr">Free Consultation</span>
                              <ul className="si_options_sub">
                                <li>Porcelain Veneers</li>
                                <li>Smile Makeover</li>
                                <li>Dental Implants</li>
                                <li>Clear Correct Invisible Braces</li>
                                <li>Gum Lift Procedure</li>
                                <li>Gaps Between Teeth</li>
                                <li>Full Mouth Reconstruction</li>
                                <li>Other </li>
                              </ul>
                            </li>
                            <li>
                              <span>Ask Us A Question</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <input
                      type="hidden"
                      name="service_inquiry"
                      id="ddl_inquiry"
                      className="js-ddl-i d-none"
                    />
                    <input
                      type="hidden"
                      name="found_on"
                      id="ddl_found"
                      className="js-ddl-e d-none"
                    />
                    <div className="col-sm-12 sbx">
                      <div className="form-group js--open-popup">
                        <span
                          id="ddl_howSelectBoxItContainer"
                          className="selectboxit-container selectboxit-container"
                          role="combobox"
                          aria-haspopup="true"
                          aria-expanded="false"
                          aria-owns="ddl_howSelectBoxItOptions"
                        >
                          <span
                            id="ddl_howSelectBoxIt"
                            className="selectboxit form-control mobile__select-form selectboxit-enabled selectboxit-btn selectboxit-open-up"
                            name="Found us on"
                            tabIndex="4"
                            unselectable="on"
                          >
                            <span className="selectboxit-option-icon-container">
                              <i
                                id="ddl_howSelectBoxItDefaultIcon"
                                className="selectboxit-default-icon selectboxit-option-icon selectboxit-container"
                                unselectable="on"
                              ></i>
                            </span>
                            <span
                              id="ddl_howSelectBoxItText"
                              className="selectboxit-text"
                              unselectable="on"
                              data-val="How Did You Find Us?"
                              aria-live="polite"
                            >
                              How Did You Find Us?
                            </span>
                            <span
                              id="ddl_howSelectBoxItArrowContainer"
                              className="selectboxit-arrow-container"
                              unselectable="on"
                            >
                              <i
                                id="ddl_howSelectBoxItArrow"
                                className="selectboxit-arrow selectboxit-default-arrow"
                                unselectable="on"
                              ></i>
                            </span>
                          </span>
                          <ul
                            id="ddl_howSelectBoxItOptions"
                            className="selectboxit-options selectboxit-list selectboxit__d js--list"
                            tabIndex="-1"
                            role="listbox"
                            aria-hidden="true"
                          >
                            <li
                              data-id="0"
                              data-val="Google"
                              data-disabled="false"
                              className="selectboxit-option selectboxit-option-first selectboxit-selected selectboxit-focus"
                              role="option"
                            >
                              <a className="selectboxit-option-anchor">
                                <span className="selectboxit-option-icon-container">
                                  <i className="selectboxit-option-icon  selectboxit-container"></i>
                                </span>
                                Google
                              </a>
                            </li>
                            <li
                              data-id="1"
                              data-val="Instagram"
                              data-disabled="false"
                              className="selectboxit-option"
                              role="option"
                            >
                              <a className="selectboxit-option-anchor">
                                <span className="selectboxit-option-icon-container">
                                  <i className="selectboxit-option-icon  selectboxit-container"></i>
                                </span>
                                Instagram
                              </a>
                            </li>
                            <li
                              data-id="2"
                              data-val="Facebook"
                              data-disabled="false"
                              className="selectboxit-option"
                              role="option"
                            >
                              <a className="selectboxit-option-anchor">
                                <span className="selectboxit-option-icon-container">
                                  <i className="selectboxit-option-icon  selectboxit-container"></i>
                                </span>
                                Facebook
                              </a>
                            </li>
                            <li
                              data-id="3"
                              data-val="Real Self"
                              data-disabled="false"
                              className="selectboxit-option"
                              role="option"
                            >
                              <a className="selectboxit-option-anchor">
                                <span className="selectboxit-option-icon-container">
                                  <i className="selectboxit-option-icon  selectboxit-container"></i>
                                </span>
                                Real Self
                              </a>
                            </li>
                            <li
                              data-id="4"
                              data-val="Radio"
                              data-disabled="false"
                              className="selectboxit-option"
                              role="option"
                            >
                              <a className="selectboxit-option-anchor">
                                <span className="selectboxit-option-icon-container">
                                  <i className="selectboxit-option-icon  selectboxit-container"></i>
                                </span>
                                Radio
                              </a>
                            </li>
                            <li
                              data-id="5"
                              data-val="Referral"
                              data-disabled="false"
                              className="selectboxit-option"
                              role="option"
                            >
                              <a className="selectboxit-option-anchor">
                                <span className="selectboxit-option-icon-container">
                                  <i className="selectboxit-option-icon  selectboxit-container"></i>
                                </span>
                                Referral
                              </a>
                            </li>
                            <li
                              data-id="6"
                              data-val="Flyer"
                              data-disabled="false"
                              className="selectboxit-option"
                              role="option"
                            >
                              <a className="selectboxit-option-anchor">
                                <span className="selectboxit-option-icon-container">
                                  <i className="selectboxit-option-icon  selectboxit-container"></i>
                                </span>
                                Flyer
                              </a>
                            </li>
                            <li
                              data-id="7"
                              data-val="Other "
                              data-disabled="false"
                              className="selectboxit-option selectboxit-option-last"
                              role="option"
                            >
                              <a className="selectboxit-option-anchor">
                                <span className="selectboxit-option-icon-container">
                                  <i className="selectboxit-option-icon  selectboxit-container"></i>
                                </span>
                                Other
                              </a>
                            </li>
                          </ul>
                        </span>
                        <img
                          src={require("../images/desktop/contact-drop-ico.png")}
                          className="contact-drop-ico"
                          alt="Contact Location"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <textarea
                          onChange={this.handleInputChange}
                          cols="20"
                          rows="2"
                          name="message"
                          id="txt_msg"
                          tabIndex="0"
                          className="form-control form__field_gray-placeholder"
                          required
                          placeholder="Your Message"
                        ></textarea>
                      </div>
                      <ReCAPTCHA
                        className="captcha"
                        sitekey="6Ldyns0UAAAAAEsOzuby5eySa1QgoPCUvtWN0vsp"
                        onChange={this.captchaOnSuccess}
                      />
                      <input
                        onClick={this.onClickHandler}
                        type="submit"
                        value="Submit Request"
                        className="btn btn-red btn-danger _red__loading"
                      />
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <div className="contact__checkbox">
                          <div className="pretty p-svg p-plain p-bigger p-jelly">
                            <input
                              id="subscribe"
                              type="checkbox"
                              name="subscribed"
                              defaultChecked="true"
                            />
                            <div className="state">
                              <label>
                                <span className="svg" className="nsssup">
                                  <i>
                                    <input
                                      type="checkbox"
                                      name="newsletter_signup"
                                      onChange={this.handleCheckmark}
                                      defaultChecked="true"
                                    />
                                  </i>
                                </span>
                                <span className="contact-checkbox__label">
                                  Sign up to the Smile Stylist newsletter and
                                  receive the latest news, specials & updates.{" "}
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  className="col-sm-6 wow animated zoomIn text-center contact-left-s"
                  data-wow-delay="0.6s"
                >
                  <img
                    src={require("../images/desktop/contact-location-banner.png")}
                    className="nsssdo"
                    alt="Contact Us Banner"
                  />
                  <h2 className="location__title">L O C A T I O N</h2>
                  <p className="location__location">
                    <a href="#map" id="contact_map_link">
                      123 Trafalgar Rd, Oakville, ON L6J 3G4, Canada
                    </a>
                  </p>
                  <div className="hours">
                    <h3>HOURS: </h3>
                    <p>
                      <b>Mon to Fri</b>: 8 am - 5 pm <br />
                      Weekends By Appointment
                    </p>
                  </div>
                  <div className="row">
                    <h3 className="contact__parking">
                      PARKING AT THE SMILE STYLIST
                    </h3>
                    <div className="col-md-6">
                      <img
                        src={require("../images/desktop/parking_1.png")}
                        alt="Parking Option 1"
                        className="img-responsive js--enlarge"
                      />
                    </div>
                    <div className="col-md-6">
                      <img
                        src={require("../images/desktop/2.png")}
                        alt="Parking Option 2"
                        className="img-responsive js--enlarge"
                      />
                    </div>
                    <p className="contact__parking_option">
                      <b>Option 1</b>: We have limited parking available in the
                      side of the building off Church St. as shown in the
                      picture above.
                    </p>
                    <p className="contact__parking_option">
                      <b>Option 2</b>: If there is no parking available please
                      park in the second level of the parking lot directly
                      across from the office and we will be happy to
                      re-reimburse you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="map" className="wow animated fadeInUp">
            <iframe
              id="nsssr"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2896.60000730323!2d-79.66953628465254!3d43.448069273689235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b5c921ea79cb9%3A0x2b01888f8d74eb9e!2s123+Trafalgar+Rd%2C+Oakville%2C+ON+L6J+3G4!5e0!3m2!1sen!2sca!4v1480010605911"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </section>
        </main>
      </Layout>
    )
  }
}

export default ContactPage
